@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@font-face {
  font-family: "Skrapbook";
  src: url("./assets/Skrapbook.otf") format("opentype");
}
body {
  background: #010101;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  list-style: none;
}

.login-page {
  background-image: url("./assets/Ankita/login.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  padding-top: 6rem;
}
.login-card {
  width: 600px;
  color: #fff;
  padding: 54px;
  border-radius: 30px;
  border: 1px solid #434343;
  background: rgba(0, 0, 0, 0.43);
  backdrop-filter: blur(16.5px);
  padding-top: 4rem;
  margin-left: 4rem;
  padding-bottom: 8rem;
}
.login-card h1 {
  color: #fff;

  font-size: 36px;
  margin-top: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.login-card form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
form {
  margin-top: 2rem;
}
form input {
  margin-bottom: 1rem;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
  background: #4b4b4b;
  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 14px;
}
form input::placeholder {
  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  opacity: 0.8;
  line-height: normal;
}

form button {
  border-radius: 4.632px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  width: fit-content;
  padding: 7px 32px;
  color: #000;
  margin-top: 1.4rem;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.611px;
}
form button img {
  width: 20px;
}
.user-navbar {
  /* background: linear-gradient(
    356deg,
    rgba(0, 0, 0, 0.5) 40.74%,
    rgba(0, 0, 0, 0) 87.59%
  ); */
  position: fixed;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  top: 0;
  justify-content: space-between;
  z-index: 4;
  padding: 0 4rem;
}
.user {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  font-size: 20.679px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.163px; /* 86.307% */
  letter-spacing: -0.77px;
}
.user img {
  width: 55px;
}
.logo img {
  width: 170px;
}
.home-page-landing {
  width: 100%;
  /* min-height: 100vh; */
  height: 1300px;
}
@keyframes backgroundAnimation {
  0% {
    background-image: url("./assets/Ankita/ankita1.png");
  }
  33% {
    background-image: url("./assets/Ankita/ankita2.png");
  }
  66% {
    background-image: url("./assets/Ankita/ankita3.png");
  }
  100% {
    background-image: url("./assets/Ankita/ankita1.png");
  }
}

.home-page-landing {
  animation: backgroundAnimation 10s ease-in-out infinite;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.home-page-landing-content {
  position: absolute;
  top: 35%;
  color: #fff;
  left: 4rem;
}
.content h1 {
  font-family: "Skrapbook";
  font-size: 90px;
  margin-top: 2rem;
}
.content p {
  color: rgba(231, 231, 231, 0.8);
  width: 76%;
  font-size: 20.635px;
  font-style: normal;
  margin-top: 1rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.469px;
}
.btns {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 10px;
  margin-top: 1.5rem;
}
button {
  cursor: pointer;
}
.btns button {
  padding: 11px 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  border-radius: 4.632px;
  background: #fff;
  color: #000;
  font-size: 20.361px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.611px;
}
.btns button.secondary {
  background: #505252;

  color: #fff;
}
.recommended {
  width: 93%;
  margin: 0 auto;
  margin-bottom: 3rem;
  position: relative;
  top: -140px;
}
.recomm-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1.4rem;
}
.recommended p {
  color: #fff;
  margin-bottom: 1rem;

  font-size: 26.93px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.988px;
}
.recomm-item:hover {
  cursor: pointer;
}
.recomm-item img {
  width: 100%;
}
.sound-element {
  position: absolute;
  top: -100px;
  visibility: hidden;
}
.recomm-item-text {
  /* display: none; */
  transform: translateY(150%);
  transition: all 0.3s ease-in-out;
  left: 10px;
  position: absolute;
  padding-left: 10px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.43);
  backdrop-filter: blur(16.5px);
  bottom: 0;
  left: 0;
}
.recomm-item {
  position: relative;
  overflow: hidden;
}
.recomm-item:hover .recomm-item-text {
  transform: translateY(0);
  /* display: unset; */
}
.recomm-item {
  width: 100%;
}

.recomm-item-text h1 {
  color: #fff;

  font-size: 20.772px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.375px;
}

.recomm-item-text p {
  color: #fff;
  opacity: 0.7;
  font-size: 16.772px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.375px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  z-index: 11;
  overflow-x: scroll;
  /* padding-top: 4rem; */
}
.overflow-hidden {
  overflow: hidden;
}
.close-button svg {
  cursor: pointer;
  width: 22px;
  position: absolute;
  right: 36px;
  top: 15px;
}
.detail-modal {
  /* width: 50%; */
  max-width: 700px;
  height: 100%;
}
.modal-container {
  border-radius: 18px;
  background: #1c1c1c;
  min-height: 100vh;
  padding: 30px;
  padding-top: 50px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}
.single-details {
  width: 100%;
}
.single-modal-img {
  width: 100%;
}
.single-modal-img img {
  width: 100%;
}
.modal-desc {
  text-align: left;
  margin-top: 1rem;
}
.modal-desc h1 {
  font-family: "Skrapbook";
  color: #fff;
  font-size: 58.631px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -2.539px;
}
.modal-desc p {
  color: #414141;
  font-family: "Skrapbook";
  font-size: 42.45px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1.574px;
}
.modal-desc span {
  color: #fff;
  opacity: 0.65;
  margin-top: 17px;
  display: block;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.related-img-list {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: left;
}
.related-img-list img {
  width: 100%;
}
.related-img {
  text-align: left;
  margin-top: 2rem;
}
.related-img p {
  margin-bottom: 1rem;

  color: #fff;

  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.08px;
}
@media (max-width: 700px) {
  .detail-modal {
    min-width: unset;
    width: 90%;
  }
  .login-card {
    width: 90%;
    margin-left: 0;
    margin: 0 auto;
    padding: 30px;
  }
  .modal-desc span {
    font-size: 14px;
  }
  .modal-desc h1 {
    font-size: 36px;
  }
  .modal-desc p {
    font-size: 24px;
  }
  .login-card h1 {
    font-size: 24px;
    margin-top: 1rem;
  }
  .login-card > img {
    width: 34px;
  }
  form button {
    font-size: 16px;
  }
  .login-page {
    padding-top: 4rem;
  }
  form input {
    padding: 8px;
    font-size: 14px;
  }
  form {
    margin-top: 10px;
  }
  form input::placeholder {
    font-size: 14px;
    opacity: 0.7;
    font-weight: normal;
  }
  .related-img p {
    font-size: 20px;
  }
  .modal-container {
    padding: 15px;
  }
}

@media (max-width: 600px) {
  .recomm-item {
    min-width: 200px;
    width: unset;
  }
  .recomm-list {
    width: 100%;
    overflow: scroll;
  }
  .user-navbar {
    padding: 0 2rem;
  }
  .logo img {
    width: 120px;
  }
  .user img {
    width: 35px;
  }
  .recommended p {
    font-size: 18px;
  }
  .user {
    font-size: 16px;
  }
  @keyframes backgroundAnimation {
    0% {
      background-image: url("./assets/Ankita/ankita1mobile.png");
    }
    50% {
      background-image: url("./assets/Ankita/ankita2mobile.png");
    }
    100% {
      background-image: url("./assets/Ankita/ankita1mobile.png");
    }
  }
  .home-page-landing {
    height: 800px;
  }
  .content h1 {
    font-size: 36px;
    margin-top: 10px;
  }
  .content p {
    font-size: 14px;
    width: 100%;
    margin-top: 10px;
  }
  .content > img {
    width: 90px;
  }
  .home-page-landing-content {
    left: 0;
    padding: 0 1rem;
  }
  .btns button {
    font-size: 16px;
    padding: 8px 26px;
  }
  .btns button img {
    width: 18px;
  }
  .home-page-landing-content {
    top: 40%;
  }
  .recommended {
    top: -100px;
  }
  .recomm-item-text h1 {
    font-size: 17px;
  }
  .recomm-item-text p {
    font-size: 16px;
    font-weight: 500;
  }
}
